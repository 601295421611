import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { ApplicationConfig, ErrorHandler, importProvidersFrom, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideOAuthClient } from 'angular-oauth2-oidc';
import { APP_ROUTES } from './app.routes';
import { AppAuthenticationService, AuthInterceptor } from '@cumlaude/shared-authentication';
import { BugsnagService } from '@cumlaude/bugsnag';
import { AdminBugsnagService } from '@cumlaude/admin-bugsnag';
import { AdminAuthenticationService } from '@cumlaude/admin-authentication';
import { provideToastr } from 'ngx-toastr';
import { ParamEncodingInterceptor } from '@cumlaude/shared-utils';
import { ENV_CONFIG, EnvConfiguration } from '@cumlaude/shared-configuration';
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular';
import { provideRouter } from '@angular/router';
import { AppMenuService } from '@cumlaude/shared-components-menu';
import { AdminMenuService } from './services/admin-menu.service';

export const createAppConfig = (envConfig: EnvConfiguration): ApplicationConfig => ({
	providers: [
		{ provide: ENV_CONFIG, useValue: envConfig },
		importProvidersFrom(BrowserModule),
		provideAnimations(),
		provideToastr({ closeButton: true }),
		provideOAuthClient(),
		{
			provide: HTTP_INTERCEPTORS,
			multi: true,
			useClass: AuthInterceptor,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: ParamEncodingInterceptor,
		},
		{
			provide: BugsnagService,
			useClass: AdminBugsnagService,
		},
		{
			provide: ErrorHandler,
			useFactory(envConfig: EnvConfiguration) {
				if (envConfig.bugsnagApiKey) {
					return new BugsnagErrorHandler();
				}
				return new ErrorHandler();
			},
			deps: [ENV_CONFIG],
		},
		{
			provide: AppAuthenticationService,
			useClass: AdminAuthenticationService,
		},
		{
			provide: AppMenuService,
			useClass: AdminMenuService,
		},
		{
			provide: LOCALE_ID,
			useValue: 'nl-NL',
		},
		provideHttpClient(withInterceptorsFromDi()),
		provideRouter(APP_ROUTES),
	],
});
