import { Component, inject } from '@angular/core';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { RestService } from '@cumlaude/shared-services';
import { Router } from '@angular/router';
import { InstellingLabelsComponent } from '../../components/instelling-labels/instelling-labels.component';
import { RBestuur } from '@cumlaude/service-contract';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-besturen-page',
	templateUrl: './besturen-page.component.html',
	styleUrls: ['./besturen-page.component.scss'],
	imports: [AsyncPipe, ButtonComponent, InstantSearchBoxComponent, InstellingLabelsComponent],
})
export class BesturenPageComponent {
	private readonly restService = inject(RestService);
	private readonly router = inject(Router);

	besturen$: Observable<RBestuur[]>;
	searchInput$ = new BehaviorSubject<string>('');

	constructor() {
		const besturenObservable = this.restService.getBesturen();
		this.besturen$ = combineLatest([besturenObservable, this.searchInput$]).pipe(
			map(([instellingen, searchInput]) => this.filterBesturen(instellingen, searchInput))
		);
	}

	private filterBesturen(besturen: RBestuur[], searchInput: string) {
		return besturen.filter((bestuur) => {
			return (
				includesIgnoreCaseAndDiacritics(bestuur.naam, searchInput) ||
				bestuur.instellingen?.some((instelling) => includesIgnoreCaseAndDiacritics(instelling.naam, searchInput))
			);
		});
	}

	openBestuurToevoegen() {
		this.router.navigate(['bestuur', 'new']);
	}

	gotoBestuur(bestuur: RBestuur) {
		this.router.navigate(['bestuur', bestuur.id]);
	}
}
