import { Injectable, OnDestroy, inject } from '@angular/core';
import { AppAuthenticationService, AuthService } from '@cumlaude/shared-authentication';
import { Subscription } from 'rxjs';
import { AdminBugsnagService } from '@cumlaude/admin-bugsnag';

@Injectable({
	providedIn: 'root',
})
export class AdminAuthenticationService extends AppAuthenticationService implements OnDestroy {
	private readonly subscriptions: Subscription[] = [];

	constructor() {
		super();
		const adminBugsnagService = inject(AdminBugsnagService);
		const authService = inject(AuthService);
		this.subscriptions.push(
			authService.loggedIn$.subscribe(() => {
				const userDisplayName = authService.getUserDisplayName();
				adminBugsnagService.setUser(userDisplayName);
			})
		);
	}

	ngOnDestroy() {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	clearSessionStorage() {
		//Nog geen Admin specifieke session storage
	}

	getOAuthParams() {
		return { federate: 'keyhub' };
	}
}
