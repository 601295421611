import { Component, inject } from '@angular/core';
import { HistoryService } from '@cumlaude/shared-services';
import { RouterLink, RouterOutlet } from '@angular/router';
import { AdminHeaderComponent, HamburgerMenuComponent } from '@cumlaude/shared-components-menu';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	imports: [RouterOutlet, RouterLink, HamburgerMenuComponent, AdminHeaderComponent],
})
export class AppComponent {
	// noinspection JSUnusedGlobalSymbols
	/**
	 * HistoryService moet in het hoofd component geinject worden zodat altijd de history getracked wordt tijdens het gebruiken van de applicatie.
	 * En op die manier dan de terug knoppen kunnen werken.
	 */
	historyService: HistoryService = inject(HistoryService);
}
