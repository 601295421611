import { Component, OnInit, inject } from '@angular/core';
import { ENV_CONFIG, EnvConfiguration, getFriendlyEnvironmentName } from '@cumlaude/shared-configuration';
import { MagisterInstellingSettingsComponent } from './magister-instelling-settings.component';
import { RMagisterInstelling } from '@cumlaude/service-contract';
import { InstellingLabelsComponent } from '../../components/instelling-labels/instelling-labels.component';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '@cumlaude/shared-services';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-magister-instelling-bewerken-page',
	templateUrl: './magister-instelling-bewerken-page.component.html',
	styleUrl: './magister-instelling-bewerken-page.component.scss',
	imports: [MagisterInstellingSettingsComponent, InstellingLabelsComponent, AsyncPipe],
})
export class MagisterInstellingBewerkenPageComponent implements OnInit {
	private readonly route = inject(ActivatedRoute);
	private readonly restService = inject(RestService);

	somtodayUrl: string;

	enviromentName: string;

	instelling$!: Observable<RMagisterInstelling>;

	private id!: number;

	constructor() {
		const envConfig = inject<EnvConfiguration>(ENV_CONFIG);
		this.enviromentName = getFriendlyEnvironmentName(envConfig.environmentName);
		this.somtodayUrl = envConfig.somtodayUrl!;
	}

	ngOnInit() {
		const idParam = this.route.snapshot.paramMap.get('id');
		this.id = Number.parseInt(idParam!);
		if (isNaN(this.id)) throw new Error(`Invalid ID: ${idParam}`);

		this.instelling$ = this.restService.getMagisterInstelling(this.id);
	}
}
