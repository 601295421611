import { Injectable, inject } from '@angular/core';
import { RBestuur, RInstelling } from '@cumlaude/service-contract';
import { firstValueFrom, forkJoin, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RestService } from '@cumlaude/shared-services';

@Injectable({
	providedIn: 'root',
})
export class BestuurService {
	protected readonly restService = inject(RestService);

	async addInstellingenToBestuur(bestuur: RBestuur, instellingen: RInstelling[]) {
		await this.updateInstellingenBijBestuur(bestuur, instellingen, true);
	}

	async removeInstellingenFromBestuur(bestuur: RBestuur, instellingen: RInstelling[]) {
		await this.updateInstellingenBijBestuur(bestuur, instellingen, false);
	}

	private async updateInstellingenBijBestuur(bestuur: RBestuur, instellingen: RInstelling[], add: boolean) {
		const updated = instellingen.map((instelling) => this.updateInstelling(bestuur, instelling, add));
		await firstValueFrom(forkJoin(updated), { defaultValue: [] });
	}

	private updateInstelling(bestuur: RBestuur, instelling: RInstelling, add: boolean): Observable<RInstelling | undefined> {
		const nieuwInstelling = {
			...instelling,
			bestuur: add ? bestuur : undefined,
			bestuurEnabled: add,
		};

		return this.restService.putInstelling(nieuwInstelling).pipe(
			catchError(() => {
				return of();
			})
		);
	}
}
