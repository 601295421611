import { inject, Injectable, signal } from '@angular/core';
import { AppMenuService } from '@cumlaude/shared-components-menu';
import { ENV_CONFIG, EnvConfiguration, getFriendlyEnvironmentName } from '@cumlaude/shared-configuration';

@Injectable({
	providedIn: 'root',
})
export class AdminMenuService extends AppMenuService {
	readonly envConfig: EnvConfiguration = inject(ENV_CONFIG);

	topLevelMenu = signal([
		{ name: getFriendlyEnvironmentName(this.envConfig.environmentName), url: '/instelling' },
		{ name: 'Besturen', url: '/bestuur' },
	]).asReadonly();

	tabMenu = signal([]).asReadonly();
}
