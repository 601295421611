import { ChangeDetectionStrategy, Component, computed, Input, OnInit, signal, inject } from '@angular/core';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { RBestuur, RInstelling } from '@cumlaude/service-contract';
import { RestService } from '@cumlaude/shared-services';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { isEmpty } from 'lodash-es';
import { firstValueFrom, map, Observable, tap } from 'rxjs';
import { BugsnagService } from '@cumlaude/bugsnag';
import { CheckboxComponent, InstantSearchBoxComponent } from '@cumlaude/shared-components-inputs';
import { AsyncPipe } from '@angular/common';
import { InstellingenListComponent } from '../../components/instellingen-list/instellingen-list.component';
import { BestuurService } from '../../services/bestuur.service';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-bestuur-settings',
	templateUrl: './bestuur-settings.component.html',
	styleUrl: './bestuur-settings.component.scss',
	imports: [ButtonComponent, InstantSearchBoxComponent, CheckboxComponent, AsyncPipe, InstellingenListComponent],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BestuurSettingsComponent implements OnInit {
	protected readonly restService = inject(RestService);
	protected readonly toastr = inject(ToastrService);
	protected readonly bugsnag = inject(BugsnagService);
	protected readonly router = inject(Router);
	protected readonly bestuurService = inject(BestuurService);

	@Input()
	bestuur?: RBestuur;

	nieuw: boolean = true;

	naam = signal('');
	searchInput = signal('');
	toonSelectie = signal(false);
	isFilled = computed(() => !isEmpty(this.naam()));

	checkedInstellingen = signal<RInstelling[]>([]);

	disabledInstellingen = signal<RInstelling[]>([]);

	instellingen$: Observable<RInstelling[]>;

	constructor() {
		this.instellingen$ = this.restService.getInstellingen().pipe(
			map((instellingen) => {
				return instellingen.sort((a, b) => {
					if (a.bestuur && a.bestuur.id !== this.bestuur?.id && b.actief) return 1;
					if (b.bestuur && b.bestuur.id !== this.bestuur?.id && a.actief) return -1;
					return 0;
				});
			}),
			tap((instellingen) => {
				this.checkedInstellingen.set(instellingen.filter((instelling) => this.bestuur && instelling.bestuur?.id === this.bestuur.id));
				this.disabledInstellingen.set(instellingen.filter((instelling) => instelling.bestuur && instelling.bestuur.id !== this.bestuur?.id));
			})
		);
	}

	ngOnInit() {
		if (this.bestuur) {
			this.nieuw = false;
			this.naam.set(this.bestuur.naam);
			this.toonSelectie.set(true);
		}
	}

	annuleren() {
		this.gotoBestuur(this.bestuur!);
	}

	async toevoegen(instellingen: RInstelling[]) {
		try {
			const bestuurNaam = this.naam();
			const bestuur = await firstValueFrom(
				this.restService.postBestuur({
					$type: 'bestuur.RBestuur',
					naam: bestuurNaam,
				})
			);

			await this.updateInstellingen(bestuur, instellingen);

			this.gotoBestuur(bestuur);
			this.toastr.success(`Bestuur ${bestuurNaam} is aangemaakt.`);
		} catch (err) {
			this.toastr.error(`Er ging iets fout bij het toevoegen van het bestuur.`);
		}
	}

	private gotoBestuur(bestuur: RBestuur) {
		this.router.navigate(['bestuur', bestuur.id]);
	}

	async opslaan(instellingen: RInstelling[]) {
		try {
			const bestuur = await firstValueFrom(
				this.restService.putBestuur({
					...this.bestuur,
					naam: this.naam(),
				})
			);
			await this.updateInstellingen(bestuur, instellingen);

			this.gotoBestuur(bestuur);
			this.toastr.success(`Wijzigingen zijn opgeslagen.`);
		} catch (err) {
			this.toastr.error(`Wijzigingen opslaan is mislukt.`);
		}
	}

	private async updateInstellingen(bestuur: RBestuur, instellingen: RInstelling[]) {
		const checkedInstellingen = this.checkedInstellingen();

		const added = instellingen.filter((instelling) => !instelling.bestuur && checkedInstellingen.find((search) => search.id === instelling.id));
		await this.bestuurService.addInstellingenToBestuur(bestuur, added);

		const removed = instellingen.filter(
			(instelling) =>
				instelling.bestuur && instelling.bestuur.id === bestuur.id && !checkedInstellingen.find((search) => search.id === instelling.id)
		);
		await this.bestuurService.removeInstellingenFromBestuur(bestuur, removed);
	}

	updateChecked(checkedInstelling: RInstelling) {
		if (checkedInstelling.bestuur && checkedInstelling.bestuur.id !== this.bestuur!.id) return;

		const huidigeInstellingen = this.checkedInstellingen();
		if (huidigeInstellingen.find((search) => search.id === checkedInstelling.id)) {
			this.checkedInstellingen.set(huidigeInstellingen.filter((instelling) => instelling.id !== checkedInstelling.id));
		} else {
			this.checkedInstellingen.set([...huidigeInstellingen, checkedInstelling]);
		}
	}

	filterInstellingen(instellingen: RInstelling[]) {
		return instellingen
			.filter((instelling) => !this.toonSelectie() || this.checkedInstellingen().find((search) => search.id === instelling.id))
			.filter((instelling) => includesIgnoreCaseAndDiacritics(instelling.naam, this.searchInput()));
	}
}
