import { Component, inject } from '@angular/core';
import { HistoryService, RestService } from '@cumlaude/shared-services';
import { BestuurSettingsComponent } from './bestuur-settings.component';
import { AsyncPipe } from '@angular/common';
import { Observable } from 'rxjs';
import { RBestuur } from '@cumlaude/service-contract';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-bestuur-bewerken-page',
	templateUrl: './bestuur-bewerken-page.component.html',
	styleUrl: './bestuur-page.component.scss',
	imports: [BestuurSettingsComponent, AsyncPipe],
})
export class BestuurBewerkenPageComponent {
	private readonly route = inject(ActivatedRoute);
	private readonly historyService = inject(HistoryService);
	private readonly restService = inject(RestService);

	bestuur$!: Observable<RBestuur>;

	private id!: number;

	goBack() {
		this.historyService.back();
	}

	ngOnInit() {
		const idParam = this.route.snapshot.paramMap.get('id');
		this.id = Number.parseInt(idParam!);
		if (isNaN(this.id)) throw new Error(`Invalid ID: ${idParam}`);

		this.bestuur$ = this.restService.getBestuur(this.id);
	}
}
